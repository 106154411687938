import { inject } from '@angular/core';
import { DateProxyPipe } from '@desquare/components/common/src/pipe/pipe/date-proxy.pipe';
import { NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import moment, { Moment } from 'moment';
import { DateTime } from 'ts-luxon';

/**
 * returns a string 'HH:mm' | null
 * @param time
 * @returns 'HH:mm' | null
 */
export function getLocalizedTime(time: string | Moment) {
  if (typeof time === 'string') {
    const dateTime = getDateTime(time);
    return moment(dateTime).local().format('HH:mm');
  } else if (moment.isMoment(time)) {
    return moment(time).local().format('HH:mm');
  } else {
    return null;
  }
}

export function getDateTime(time: string, day?: number, _date?: Date) {
  const date = new Date(
    (_date ? _date : new Date()).toISOString().split('T')[0] + 'T' + time,
  );

  if (day) {
    date.setDate(day);
  }

  return date;
}
/**
 * returns 00:00:00 string formatted time
 * @param date
 * @returns
 */
export function getISOTimeNoTz(time: string | Date | Moment) {
  if (typeof time === 'string') {
    const dateTime = getDateTime(time);
    return moment(dateTime).format('HH:mm:ss');
  } else if (moment.isMoment(time)) {
    return moment(time).format('HH:mm:ss');
  } else {
    return null;
  }
}

/**
 * return '00:00:00Z' ZULU formatted time, DANGEROUS
 * @param date
 * @returns
 */
export function getISOTime(date: string | Date | Moment) {
  const splitChar = 'T';
  let time = '00:00:00Z';
  let splitValues: string[] = [];

  if (date instanceof Date || moment.isMoment(date)) {
    splitValues = date.toISOString().split(splitChar);
  } else {
    splitValues = date.split(splitChar);
  }

  if (splitValues[1]) {
    time = splitValues[1];
  }

  return time;
}

export const timeout = (ms: number) => {
  return new Promise<void>((resolve) => {
    setTimeout(resolve, ms);
  });
};

export function timeStringToStruct(value: string): NgbTimeStruct {
  // HH:mm:ss
  const timeElements = value.split(':');
  return {
    hour: parseInt(timeElements[0], 10),
    minute: parseInt(timeElements[1], 10),
    second: timeElements.length > 2 ? parseInt(timeElements[2], 10) : 0,
  };
}
/** HH:mm or HH:mm:ss */
export function timeStructToString(
  timeStruct: NgbTimeStruct,
  showSecond: boolean,
) {
  let timeString = `${String(timeStruct.hour).padStart(2, '0')}:${String(
    timeStruct.minute,
  ).padStart(2, '0')}`;
  if (showSecond) {
    timeString += `:${String(timeStruct.second).padStart(2, '0')}`;
  }

  return timeString;
}

/** this function converts time format from HH:MM (HM) to HH:MM:SS (HMS) */
export const formatTimeHMToHMS = (timeHMformatted: string) => {
  const HH = timeHMformatted.split(':')[0];
  const MM = timeHMformatted.split(':')[1];
  // the back end needs the time format to be in 24hrs HH:MM:SS
  // since the SS (seconds) is not specified it should be 00
  return `${HH}:${MM}:00`;
};

/** this function converts time format from HH:MM:SS or HH:MM:S (HMS) to HH:MM (HM) */
export const formatTimeHMSToHM = (timeHMformatted: string) => {
  const HH = timeHMformatted.split(':')[0];
  const MM = timeHMformatted.split(':')[1];
  // the back end needs the time format to be in 24hrs HH:MM:SS
  // since the SS (seconds) is not specified it should be 00
  return `${HH}:${MM}`;
};

/** Return HH:mm:ss  from date with time zone offset */
export function getDeviceTime(time: string, timeZoneOffset: string) {
  return moment(time).utcOffset(timeZoneOffset).format('HH:mm:ss');
}

export const playlistScheduleParse = (
  startDateTime: string | null,
  endDateTime: string | null,
  translatePipe: TranslateService,
  dateProxyPipe: DateProxyPipe,
): string => {
  const startDate = startDateTime ? startDateTime.split('T')[0] : '00:00:00';
  const startTime = startDateTime ? startDateTime.split('T')[1] : '00:00';
  const endDate = endDateTime ? endDateTime.split('T')[0] : '00:00:00';
  const endTime = endDateTime ? endDateTime.split('T')[1] : '00:00';

  console.log('startDate', startDate);
  console.log('endDate', endDate);
  console.log('startTime', startTime);
  console.log('endTime', endTime);

  if (
    startDate === '00:00:00' &&
    endDate === '00:00:00' &&
    startTime === '00:00' &&
    endTime === '00:00'
  ) {
    return translatePipe.instant('PLAYLIST_SCHEDULE.PLAYING_EVERY_DAY_ALL_DAY');
  }
  if (
    startDate !== '00:00:00' &&
    endDate === '00:00:00' &&
    startTime === '00:00' &&
    endTime === '00:00'
  ) {
    return translatePipe.instant('PLAYLIST_SCHEDULE.PLAYING_FROM_DATE_NO_END', {
      startDate: dateProxyPipe.transform(startDateTime as string, 'shortDate'),
    });
  }
  if (
    startDate === '00:00:00' &&
    endDate !== '00:00:00' &&
    startTime === '00:00' &&
    endTime === '00:00'
  ) {
    return translatePipe.instant('PLAYLIST_SCHEDULE.PLAYING_TO_DATE_NO_START', {
      endDate: dateProxyPipe.transform(endDateTime as string, 'shortDate'),
    });
  }
  if (
    startDate === '00:00:00' &&
    endDate === '00:00:00' &&
    startTime !== '00:00' &&
    endTime !== '00:00'
  ) {
    return translatePipe.instant(
      'PLAYLIST_SCHEDULE.PLAYING_EVERY_DAY_TIME_RANGE',
      {
        startTime: dateProxyPipe.transform(
          startDateTime as string,
          'shortTime',
        ),
        endTime: dateProxyPipe.transform(endDateTime as string, 'shortTime'),
      },
    );
  }
  if (
    startDate === '00:00:00' &&
    endDate === '00:00:00' &&
    startTime !== '00:00' &&
    endTime === '00:00'
  ) {
    return translatePipe.instant(
      'PLAYLIST_SCHEDULE.PLAYING_EVERY_DAY_FROM_TIME',
      {
        startTime: dateProxyPipe.transform(
          startDateTime as string,
          'shortTime',
        ),
      },
    );
  }
  if (
    startDate === '00:00:00' &&
    endDate === '00:00:00' &&
    startTime === '00:00' &&
    endTime !== '00:00'
  ) {
    return translatePipe.instant(
      'PLAYLIST_SCHEDULE.PLAYING_EVERY_DAY_UNTIL_TIME',
      {
        endTime: dateProxyPipe.transform(endDateTime as string, 'shortTime'),
      },
    );
  }

  // New combinations with translations
  if (
    startDate !== '00:00:00' &&
    endDate !== '00:00:00' &&
    startTime === '00:00' &&
    endTime === '00:00'
  ) {
    return translatePipe.instant(
      'PLAYLIST_SCHEDULE.PLAYING_DATE_RANGE_ALL_DAY',
      {
        startDate: dateProxyPipe.transform(
          startDateTime as string,
          'shortDate',
        ),
        endDate: dateProxyPipe.transform(endDateTime as string, 'shortDate'),
      },
    );
  }
  if (
    startDate !== '00:00:00' &&
    endDate !== '00:00:00' &&
    startTime !== '00:00' &&
    endTime !== '00:00'
  ) {
    return translatePipe.instant('PLAYLIST_SCHEDULE.PLAYING_FULL_RANGE', {
      startDate: dateProxyPipe.transform(startDateTime as string, 'shortDate'),
      startTime: dateProxyPipe.transform(startDateTime as string, 'shortTime'),
      endDate: dateProxyPipe.transform(endDateTime as string, 'shortDate'),
      endTime: dateProxyPipe.transform(endDateTime as string, 'shortTime'),
    });
  }
  if (
    startDate !== '00:00:00' &&
    endDate !== '00:00:00' &&
    startTime !== '00:00' &&
    endTime === '00:00'
  ) {
    return translatePipe.instant(
      'PLAYLIST_SCHEDULE.PLAYING_DATE_RANGE_FROM_TIME',
      {
        startDate: dateProxyPipe.transform(
          startDateTime as string,
          'shortDate',
        ),
        startTime: dateProxyPipe.transform(
          startDateTime as string,
          'shortTime',
        ),
        endDate: dateProxyPipe.transform(endDateTime as string, 'shortDate'),
      },
    );
  }
  if (
    startDate !== '00:00:00' &&
    endDate !== '00:00:00' &&
    startTime === '00:00' &&
    endTime !== '00:00'
  ) {
    return translatePipe.instant(
      'PLAYLIST_SCHEDULE.PLAYING_DATE_RANGE_UNTIL_TIME',
      {
        startDate: dateProxyPipe.transform(
          startDateTime as string,
          'shortDate',
        ),
        endDate: dateProxyPipe.transform(endDateTime as string, 'shortDate'),
        endTime: dateProxyPipe.transform(endDateTime as string, 'shortTime'),
      },
    );
  }
  if (
    startDate !== '00:00:00' &&
    endDate === '00:00:00' &&
    startTime !== '00:00' &&
    endTime !== '00:00'
  ) {
    return translatePipe.instant(
      'PLAYLIST_SCHEDULE.PLAYING_FROM_DATE_DAILY_TIME_RANGE',
      {
        startDate: dateProxyPipe.transform(
          startDateTime as string,
          'shortDate',
        ),
        startTime: dateProxyPipe.transform(
          startDateTime as string,
          'shortTime',
        ),
        endTime: dateProxyPipe.transform(endDateTime as string, 'shortTime'),
      },
    );
  }
  if (
    startDate !== '00:00:00' &&
    endDate === '00:00:00' &&
    startTime !== '00:00' &&
    endTime === '00:00'
  ) {
    return translatePipe.instant(
      'PLAYLIST_SCHEDULE.PLAYING_FROM_DATE_DAILY_FROM_TIME',
      {
        startDate: dateProxyPipe.transform(
          startDateTime as string,
          'shortDate',
        ),
        startTime: dateProxyPipe.transform(
          startDateTime as string,
          'shortTime',
        ),
      },
    );
  }
  if (
    startDate !== '00:00:00' &&
    endDate === '00:00:00' &&
    startTime === '00:00' &&
    endTime !== '00:00'
  ) {
    return translatePipe.instant(
      'PLAYLIST_SCHEDULE.PLAYING_FROM_DATE_DAILY_UNTIL_TIME',
      {
        startDate: dateProxyPipe.transform(
          startDateTime as string,
          'shortDate',
        ),
        endTime: dateProxyPipe.transform(endDateTime as string, 'shortTime'),
      },
    );
  }
  if (
    startDate === '00:00:00' &&
    endDate !== '00:00:00' &&
    startTime !== '00:00' &&
    endTime !== '00:00'
  ) {
    return translatePipe.instant(
      'PLAYLIST_SCHEDULE.PLAYING_UNTIL_DATE_DAILY_TIME_RANGE',
      {
        endDate: dateProxyPipe.transform(endDateTime as string, 'shortDate'),
        startTime: dateProxyPipe.transform(
          startDateTime as string,
          'shortTime',
        ),
        endTime: dateProxyPipe.transform(endDateTime as string, 'shortTime'),
      },
    );
  }
  if (
    startDate === '00:00:00' &&
    endDate !== '00:00:00' &&
    startTime !== '00:00' &&
    endTime === '00:00'
  ) {
    return translatePipe.instant(
      'PLAYLIST_SCHEDULE.PLAYING_UNTIL_DATE_DAILY_FROM_TIME',
      {
        endDate: dateProxyPipe.transform(endDateTime as string, 'shortDate'),
        startTime: dateProxyPipe.transform(
          startDateTime as string,
          'shortTime',
        ),
      },
    );
  }
  if (
    startDate === '00:00:00' &&
    endDate !== '00:00:00' &&
    startTime === '00:00' &&
    endTime !== '00:00'
  ) {
    return translatePipe.instant(
      'PLAYLIST_SCHEDULE.PLAYING_UNTIL_DATE_DAILY_UNTIL_TIME',
      {
        endDate: dateProxyPipe.transform(endDateTime as string, 'shortDate'),
        endTime: dateProxyPipe.transform(endDateTime as string, 'shortTime'),
      },
    );
  }

  return translatePipe.instant('PLAYLIST_SCHEDULE.UNABLE_TO_PARSE');
};
